import { i18n } from '@/commons/config/i18n';
import { ApiErrorRecord } from '@/commons/models';
import { isJSONString } from '@/commons/utils/strings';

export default class ApiError {
  errorCode: number;
  message: string;
  translationKey: string;

  constructor(errorRecord?: ApiErrorRecord) {
    const defaultErrorRecord = { status: 0, data: null, translationKey: null };
    const { status, data, translationKey } = errorRecord ?? defaultErrorRecord;

    this.errorCode = status;
    const exceptionMessage =
      data?.content?.exceptionMessage || data?.code || data?.exceptionMessage;
    this.message = exceptionMessage ?? '';
    this.printLog();
    this.translationKey = translationKey ?? 'error.generic';
  }

  getMessage() {
    if (!i18n.te(this.translationKey)) return this.message;

    return <string>i18n.t(this.translationKey);
  }

  getExceptionMessage(): string {
    const errorRecord = isJSONString(this.message)
      ? JSON.parse(this.message)
      : this.message;
    const message = errorRecord?.key && i18n.te(errorRecord.key)
      ? i18n.t(errorRecord.key)
      : i18n.t(this.translationKey)


      return <string>message;
  }

  private printLog() {
    if (this.message) {
      console.error(this.message);
    }
  }
}